<template>
  <v-row
    no-gutters
    class="h-100"
  >
    <v-col cols="12" offset="0" md="6" offset-md="3" class="d-flex flex-column justify-center align-center">
      <v-card
        class="w-100 rounded-lg"
      >
        <v-card-title>{{ $t('twoFactorAuthentication.resetCodeTitle') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <VerificationCodeInput
                v-model="code"
                :disabled="endTime || submitLoading"
              />
            </v-col>
            <v-col
              cols="12"
              class="text--secondary mb-12"
            >
              <span class="mr-2">{{ $t('twoFactorAuthentication.codeTimeValid') }}</span>
              <span>{{ codeLifetime }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 mb-4">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn color="info" outlined :disabled="submitLoading || generateNewCodeLoading" :loading="submitLoading || generateNewCodeLoading" @click="generateNewCode">
                {{ $t('twoFactorAuthentication.generateNewCode') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <div class="d-flex justify-end">
                <v-btn color="secondary" outlined :disabled="submitLoading || endTime" :loading="submitLoading" class="mr-2" @click="$router.push({ name: 'Login' })">
                  {{ $t('twoFactorAuthentication.cancel') }}
                </v-btn>
                <v-btn color="primary" :disabled="submitLoading || endTime" :loading="submitLoading" @click="submitHandle">
                  {{ $t('twoFactorAuthentication.save') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import services from '@/services'
import VerificationCodeInput from '@/components/VerificationCodeInput'
import moment from 'moment'

export default {
  name: 'TwoFactorAuthentication',
  components: {
    VerificationCodeInput
  },
  data () {
    return {
      submitLoading: false,
      generateNewCodeLoading: false,
      endTime: false,
      code: '',
      codeLifetime: null,
      codeLifetimeInterval: null
    }
  },
  created () {
    const codeDate = sessionStorage.getItem('expiryDate')
    if (!codeDate) {
      return
    }
    this.codeLifetimeInterval = setInterval(this.calculateTime, 1000)
    this.calculateTime()
  },
  beforeDestroy () {
    if (this.codeLifetimeInterval) {
      clearInterval(this.codeLifetimeInterval)
    }
  },
  methods: {
    calculateTime () {
      const now = moment().valueOf()
      const expiryDate = moment(sessionStorage.getItem('expiryDate')).valueOf()
      const remaining = expiryDate - now

      if (remaining <= 0) {
        this.codeLifetime = '00:00'
        this.endTime = true
        if (this.codeLifetimeInterval) {
          clearInterval(this.codeLifetimeInterval)
        }
        return
      }

      this.codeLifetime = moment(remaining).format('mm:ss')
    },
    async generateNewCode () {
      this.generateNewCodeLoading = true
      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('ResetPassword')
        const { data } = await services.post('Authentication/ResetPassword', {
          captchaToken: token,
          email: sessionStorage.getItem('email')
        })
        sessionStorage.setItem('expiryDate', data.expiryDate)
        this.codeLifetimeInterval = setInterval(this.calculateTime, 1000)
        this.calculateTime()
        this.endTime = false
      } catch (e) {}
      this.generateNewCodeLoading = false
    },
    async submitHandle () {
      this.submitLoading = true
      try {
        await services.get('Authentication/ValidateResetToken', {
          Email: sessionStorage.getItem('email'),
          ResetCode: this.code
        })

        sessionStorage.setItem('resetCode', this.code)
        await this.$router.push({ name: 'ResetPassword' })
      } catch (e) {}
      this.submitLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
